<template>
  <div v-if="visible" class="card">
    <div class="svg container">
      <img class="icon" src="/public__assets/icons/event.svg" alt="" />
      <div class="gmaps__date">
        <h2>
          {{ formatDate(start) }}
        </h2>
        <div v-if="locality" class="locality__box">
          <a
            target="blank"
            :href="`https://www.google.com/maps/search/${locality}`"
            ><img class="gmaps" src="/public__assets/icons/gmaps.png" alt=""
          /></a>
        </div>
      </div>
    </div>
    <div
      :class="{ light: settings.theme === 'Light' }"
      class="container bottom"
    >
      <a target="blank" :href="link">
        <div>
          <h1 v-html="h1"></h1>
          <h2 class="clipText">{{ h2 }}</h2>
        </div>
      </a>
    </div>
  </div>
</template>

<script>
import { format, isToday, isTomorrow, isAfter } from "date-fns";
import { months, today, tomorrow } from "../../tools/languages/dayweeks.js";
export default {
  props: {
    h1: {
      type: String,
    },
    h2: {
      type: String,
    },
    start: {
      type: String,
    },
    locality: {
      type: String,
    },
    id: {
      type: String,
    },
    link: {
      type: String,
    },
  },
  data() {
    return {
      visible: true,
    };
  },
  methods: {
    formatDate(input) {
      const date = new Date(input);
      const now = new Date();
      let formattedDate;
      if (isAfter(now, date)) {
        this.visible = false;
      } else {
        if (isToday(date)) {
          formattedDate = `${this.todayTomorrowMultilanguageHandler(
            "today",
            this.actualLanguage
          )}, ${format(date, "HH:mm")}`;
        } else if (isTomorrow(date)) {
          formattedDate = `${this.todayTomorrowMultilanguageHandler(
            "tomorrow",
            this.actualLanguage
          )}, ${format(date, "HH:mm")}`;
        } else {
          formattedDate = this.monthsMultilanguageHandler(
            format(date, "d MMMM yyyy, HH:mm"),
            this.actualLanguage
          );
        }
      }
      return formattedDate;
    },
    todayTomorrowMultilanguageHandler(day, lang) {
      if (day === "today") {
        return today[lang];
      } else {
        return tomorrow[lang];
      }
    },
    monthsMultilanguageHandler(date, language) {
      // Now replace the English month name with the translated name
      let englishMonth = date.split(" ")[1];
      let translatedMonth = months[language][englishMonth];

      // If the translated month name is undefined, it means the language is not supported
      if (!translatedMonth) {
        throw new Error(`Language '${language}' is not supported`);
      }

      // Replace the English month name with the translated name and return the result
      return date.replace(englishMonth, translatedMonth);
    },
  },
  computed: {
    actualLanguage() {
      return this.$store.getters["appSettings/actualLanguage"];
    },
    settings() {
      return this.$store.getters["appSettings/settings"];
    },
  },
  async mounted() {
    await this.$store.dispatch("appSettings/fetchSettings");
  },
};
</script>

<style scoped>
.svg .icon {
  width: 50px;
  margin-bottom: 0.3rem;
}
.card {
  position: relative;
  width: 100%;
  height: auto;
  min-height: 120px;
  background: rgba(255, 255, 255, 0.53);
  border-radius: 25px;
  border-radius: 25px;
  margin-bottom: 1.2rem;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  transition: 0.3s all;
  overflow: hidden;
}
.card.stretch {
  width: 100%;
}
.card.active {
  width: 100%;
  min-height: 300px;
  justify-content: flex-start;
}
.rotate {
  transform: rotateX(180deg);
}
.container {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0.8rem;
}
.bottom {
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
}
.bottom.light {
  background: rgba(255, 255, 255, 0.66) !important;
}
.bottom.light h1 {
  color: black;
}
.bottom.light h2 {
  background: linear-gradient(
    360deg,
    rgba(255, 255, 255, 0.66),
    rgba(0, 0, 0, 0.7)
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
.gmaps__date {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}
.container div:first-child {
  cursor: pointer;
}
h1 {
  font-size: 1rem;
  font-weight: 500;
  max-width: 100%;
}
h2 {
  font-size: 0.9rem;
  font-weight: 400;
}
.clipText {
  max-width: 80%;
  max-height: 3rem;
  background: linear-gradient(360deg, rgba(0, 0, 0, 0.7), white);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
.arrow {
  cursor: pointer;
  transition: 0.3s transform;
  position: absolute;
  top: 0.8rem;
  right: 0.8rem;
}
.locality__box {
  width: 45px;
  height: 45px;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.gmaps {
  scale: 0.5;
  cursor: pointer;
  width: 40px;
  margin-top: 0.5rem;
}
a {
  text-decoration: none;
  color: inherit;
}
@media screen and (max-width: 600px) {
  .card {
    min-height: 100px;
    margin-bottom: 0.9rem;
  }
  h1 {
    font-size: 0.9rem;
  }
  h2 {
    font-size: 0.7rem;
  }
}
</style>
<style>
@keyframes arrowRotate {
  from {
    transform: rotateX(0deg);
  }
  to {
    transform: rotateX(180deg);
  }
}
</style>
