export default {
  async fetchCards(context) {
    let lang = context.rootGetters["appSettings/actualLanguage"];
    context.commit("setLoading", true);
    try {
      const response = await fetch(
        `${process.env.VUE_APP_SERVER}/api/bigger-cards?locale=${lang}&populate=Properties,BackgroundImage,Hours`
      );
      const responseData = await response.json();
      const data = responseData.data;
      const cards = [];
      data.forEach((card) => {
        cards.push({
          id: card.id,
          title: card.attributes.Properties.Title,
          description: card.attributes.Properties.Description,
          hiddenDescription: card.attributes.Properties.HiddenDescription,
          hours: card.attributes.Hours,
          bg: card.attributes.BackgroundImage.data.attributes.url,
        });
      });
      if (cards.length < 1) {
        throw new Error("no cards defined");
      }

      context.commit("setRestaurants", cards);
      context.commit("setLoading", false);
    } catch (error) {
      context.commit("setError", {
        error: error,
        errorDescription: "we are sorry content is unavailable!",
      });
      context.commit("setLoading", false);
      console.log(error);
    }
  },
};
