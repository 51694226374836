import actions from "./actions.js";
export default {
  namespaced: true,
  state() {
    return {
      settings: {
        theme: "dark",
        languages: [],
        googleRestaurants: true,
        googlePlaces: true,
      },
      actualLanguage: process.env.VUE_APP_NATIVE_LANG_SHOTRCUT,
      isLoading: false,
      error: {},
    };
  },
  getters: {
    settings(state) {
      return state.settings;
    },
    languages(state) {
      return state.settings.languages;
    },
    actualLanguage(state) {
      return state.actualLanguage;
    },
    error(state) {
      return state.error;
    },
  },
  mutations: {
    setSettings(state, payload) {
      state.settings = payload;
    },
    setActualLanguage(state, payload) {
      let lang = "";
      switch (payload) {
        case "Polish":
          lang = "pl";
          break;
        case "German":
          lang = "de";
          break;
        case "Ukrainian":
          lang = "uk";
          break;
        case "Spanish":
          lang = "es";
          break;
        case "Turkish":
          lang = "tr";
          break;
        case "Czech":
          lang = "cs";
          break;
        case "Italian":
          lang = "it";
          break;
        case "French":
          lang = "fr";
          break;
        default:
          lang = "en";
      }
      state.actualLanguage = lang;
    },
    setError(state, payload) {
      state.error = payload;
    },
    setLoading(state, payload) {
      state.isLoading = payload;
    },
  },
  actions: actions,
  modules: {},
};
