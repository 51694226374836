<template>
  <div :class="{ light: settings.theme === 'Light' }" class="modal">
    <header>
      <div>
        <h1>{{ title }}</h1>
        <h2 v-html="h2"></h2>
      </div>
      <svg
        @click="$emit('modal-handler')"
        width="50"
        height="50"
        viewBox="0 0 45 45"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="45"
          width="45"
          height="45"
          rx="22.5"
          transform="rotate(90 45 0)"
          fill="white"
        />
        <path
          d="M13.75 18.9516L22.5 27.7016L31.25 18.9516"
          stroke="black"
          stroke-width="4"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </header>
    <div :class="{ light: settings.theme === 'Light' }" class="content">
      <h2>
        <h4 v-html="description"></h4>
        <h5 v-for="hour in formatHours(openingHours)" :key="hour">
          {{ hour }}
        </h5>
      </h2>
    </div>
  </div>
</template>

<script>
export default {
  props: ["english", "title", "h2", "description", "openingHours"],
  emits: ["modal-handler"],
  methods: {
    formatHours(hours) {
      let formattedHours = [];
      hours.forEach((hour) => {
        const fromDay =
          hour.DayFrom.charAt(0).toUpperCase() + hour.DayFrom.slice(1);
        const toDay = hour.DayTo.charAt(0).toUpperCase() + hour.DayTo.slice(1);
        const fromTime = hour.HourFrom.slice(0, 5);
        const toTime = hour.HourTo.slice(0, 5);

        if (hour.DayFrom === hour.DayTo) {
          formattedHours.push(`${fromDay}: ${fromTime} - ${toTime}`);
        } else {
          formattedHours.push(`${fromDay} - ${toDay}: ${fromTime} - ${toTime}`);
        }
      });
      return formattedHours;
    },
  },
  async mounted() {
    await this.$store.dispatch("appSettings/fetchSettings");
  },
  computed: {
    settings() {
      return this.$store.getters["appSettings/settings"];
    },
  },
};
</script>

<style scoped>
.modal {
  transition: 0.3s all;
  width: 100%;
  min-height: 50vh;
  max-height: 80vh;
  border-radius: 35px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background-color: #202020;
  padding: 0 5%;
  overflow: scroll;
  position: relative;
}
.modal.light {
  background-color: white;
}
header {
  position: sticky;
  top: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #202020;
  padding: 1rem 0;
}
.modal.light header {
  background-color: white;
}
.modal.light header h1,
.modal.light header h2,
.modal.light header rect {
  color: black;
  fill: rgba(0, 0, 0, 0.73);
}
.modal.light header path {
  stroke: white;
}
svg {
  top: 0.8rem;
  right: 0.8rem;
  cursor: pointer;
}
header div {
  min-width: 70%;
}
h1 {
  max-width: 85%;
  color: white;
  font-weight: 400;
}

h2,
h3,
h4 {
  max-width: 95%;
  color: white;
}
h2,
h3 {
  font-weight: 300;
  font-size: 1rem;
}
h4 {
  font-weight: 300;
  font-size: 1rem;
  text-decoration: none;
  color: inherit;
  white-space: pre-wrap;
  margin: 1rem 0;
}
.content {
  color: white;
}
.content.light {
  color: black;
}
.content.light h1,
.content.light h2,
.content.light h3,
.content.light a,
.content.light p,
.content.light span,
.content.light h4 {
  color: black !important;
}
a {
  text-decoration: none;
  color: inherit !important;
}
</style>
