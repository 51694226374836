export default {
  namespaced: true,
  state() {
    return {
      socialMedia: [],
      error: {},
    };
  },
  getters: {
    socialmedia(state) {
      return state.events;
    },
    error(state) {
      return state.error;
    },
  },
  mutations: {
    setSocialmedia(state, payload) {
      state.events = payload;
    },
    setError(state, payload) {
      state.error = payload;
    },
  },
  actions: {
    async fetchSocialmedia(context) {
      try {
        const response = await fetch(
          `${process.env.VUE_APP_SERVER}/api/social-medias`
        );
        const responseData = await response.json();

        const data = responseData.data;
        const medias = [];
        data.forEach((media) => {
          medias.push({
            id: media.id,
            link: media.attributes.link,
            provider: media.attributes.provider,
          });
        });
        context.commit("setSocialmedia", medias);
      } catch (error) {
        this.error = error;
        console.log("error");
      }
    },
  },
  modules: {},
};
