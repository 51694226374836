<template>
  <div class="media__container">
    <a target="blank" :href="link">
      <img :src="`/public__assets/socialMedia/${provider}.png`" alt="" />
    </a>
  </div>
</template>

<script>
export default {
  props: ["provider", "link"],
};
</script>

<style scoped>
.media__container {
  position: relative;
  width: auto;
  aspect-ratio: 1/1;
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 25px;
  border-width: 0px 1px 1px 0px;
  border-style: solid;
  border-color: rgba(255, 255, 255, 0.57);
  border-radius: 13px;
  margin-bottom: 1.2rem;
  padding: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transition: 0.3s all;
  filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.25));
}
.media__container:hover {
  transform: scale(1.1);
}
a {
  width: 45px;
  height: 45px;
}
img {
  width: 45px;
  aspect-ratio: 1/1;
}
</style>
