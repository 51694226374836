<template>
  <div class="footer__container">
    <footer>
      <h1 :class="{ light: settings.theme === 'Light' }">PoweredBy</h1>
      <a target="blank" href="https://gcodem.pl">
        <the-logo :light="settings.theme === 'Light'" />
      </a>
    </footer>
  </div>
</template>

<script>
export default {
  computed: {
    settings() {
      return this.$store.getters["appSettings/settings"];
    },
  },
  async mounted() {
    await this.$store.dispatch("appSettings/fetchSettings");
  },
};
</script>

<style scoped>
.footer__container {
  padding: 0 5%;
  margin-top: 1rem;
  margin-bottom: 100px;
  z-index: 2;
}
footer {
  padding: 0 2.5rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.5rem;
  color: white;
}
.light h1 {
  color: black;
}
footer h1 {
  font-weight: 400;
  margin-right: 1rem;
}
img {
  width: 100px;
}
.nicode,
a {
  align-self: center;
}
</style>
