<template>
  <section :id="idObject">
    <header :class="{ light: settings.theme === 'Light' }">
      <slot name="header">DEFAULT</slot>
    </header>
    <div
      class="icons__container"
      :style="{
        display: display,
        justifyContent: justifyContent,
        gap: gap,
        flexDirection: flexDirection,
        alignItems: alignItems,
        paddingLeft: paddingLeft,
      }"
    >
      <slot></slot>
    </div>
  </section>
</template>

<script>
export default {
  props: [
    "display",
    "justifyContent",
    "idObject",
    "gap",
    "flexDirection",
    "alignItems",
    "paddingLeft",
  ],
  async mounted() {
    await this.$store.dispatch("appSettings/fetchSettings");
  },
  computed: {
    settings() {
      return this.$store.getters["appSettings/settings"];
    },
  },
};
</script>

<style scoped>
header {
  padding: 1rem 0;
  color: white;
  z-index: 5;
}
header.light {
  color: black !important;
}
section {
  padding: 0 1.5rem;
  overflow: hidden;
  min-height: 30vh;
}
.icons__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
@media screen and (max-width: 610px) {
  section {
    padding: 0 0.9rem;
    overflow: hidden;
  }
  header {
    padding: 0.7rem 0;
  }
}
</style>
