export default {
  async fetchSettings(context) {
    try {
      const response = await fetch(
        `${process.env.VUE_APP_SERVER}/api/app-setting?populate=BackgroundImage,Logo`
      );
      const responseData = await response.json();

      const data = responseData.data;
      const settings = {
        id: data.id,
        theme: data.attributes.Theme,
        languages: data.attributes.Languages,
        googleRestaurants: data.attributes.GoogleRestaurants,
        googlePlaces: data.attributes.GooglePlaces,
        bg: data.attributes.BackgroundImage.data.attributes.url,
        logo: data.attributes.Logo.data.attributes.url,
      };
      context.commit("setSettings", settings);
    } catch (error) {
      context.commit("setError", {
        error: error,
        errorDescription: "we are sorry content is unavailable!",
      });
      context.commit("setLoading", false);
      console.log(error);
    }
  },
};
