<template>
  <div
    v-if="languages.length > 1"
    :class="{ light: settings.theme === 'Light' }"
    @click="dropDownHandler"
    class="dropdown"
  >
    <img
      class="flag"
      :src="'/public__assets/flags/' + language + '.svg'"
      alt=""
    />
    <h1>{{ language }}</h1>
    <img
      :class="{ active: dropdownIsVisible }"
      class="dropdown__handler"
      src="/public__assets/tools/arrow.svg"
      alt=""
    />
  </div>
  <Transition appear mode="in-out">
    <div
      v-if="dropdownIsVisible"
      :class="{ light: settings.theme === 'Light' }"
      class="dropdown__menu"
    >
      <ul>
        <li
          v-for="language in languages"
          :key="language"
          @click="changeLanguage($event.target)"
        >
          <img
            class="flag"
            :src="`/public__assets/flags/${language}.svg`"
            alt=""
          />
          <h1>{{ language }}</h1>
        </li>
      </ul>
    </div>
  </Transition>
</template>

<script>
export default {
  data() {
    return {
      dropdownIsVisible: false,
      language: process.env.VUE_APP_NATIVE_LANG,
    };
  },
  methods: {
    dropDownHandler() {
      this.dropdownIsVisible = !this.dropdownIsVisible;
    },
    changeLanguage(el) {
      this.dropDownHandler();
      this.language = el.innerText;
      this.$store.commit("appSettings/setActualLanguage", this.language);
      this.$store.dispatch("cards/fetchCards");
      this.$store.dispatch("appSettings/fetchSettings");
      this.$store.dispatch("restaurantCards/fetchCards");
      this.$store.dispatch("attractionCards/fetchCards");
      this.$store.dispatch("events/fetchEvents");
      this.$store.dispatch("extraLinks/fetchExtraLinks");
      this.$store.dispatch("socialMedia/fetchSocialmedia");
    },
  },
  computed: {
    languages() {
      return this.$store.getters["appSettings/languages"];
    },
    settings() {
      return this.$store.getters["appSettings/settings"];
    },
  },
};
</script>

<style scoped>
.dropdown {
  background-color: black;
  height: 37px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 30px;
  padding: 0.2rem;
  gap: 0.5rem;
}
.dropdown.light {
  background-color: white;
}
.dropdown.light h1 {
  color: black;
}
.dropdown__menu {
  margin-top: 0.5rem;
  border-radius: 20px;
  background: #343434;
  overflow: hidden;
}
.dropdown__menu.light {
  background: rgba(255, 255, 255, 0.53);
}
.dropdown__menu.light ul li h1 {
  color: black;
}
ul {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: left;
  gap: 0.2rem;
}
li {
  display: flex;
  align-items: center;
  justify-content: left;
  gap: 1rem;
  width: 100%;
  transition: all 0.3s;
  padding: 0.3rem 0.3rem;
  cursor: pointer;
}
li:hover {
  background-color: black;
}
.flag {
  height: 30px;
  aspect-ratio: 1/1;
}
h1 {
  color: white;
  font-size: 0.8rem;
  font-weight: 300;
}
.dropdown__handler {
  height: 30px;
  aspect-ratio: 1/1;
  rotate: 90deg;
  cursor: pointer;
  transition: 0.2s rotate;
}
.dropdown__handler.active {
  rotate: -90deg;
}
.v-enter-active,
.v-leave-active {
  transition: all 0.5s ease;
}

.v-enter-to,
.v-leave-from {
  opacity: 1;
}
.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
