export default {
  namespaced: true,
  state() {
    return {
      hours: "",
    };
  },
  getters: {
    hours(state) {
      return state.hours;
    },
  },
  mutations: {
    setHours(state, payload) {
      state.hours = payload;
    },
  },
  actions: {
    async fetchHours(context) {
      try {
        const response = await fetch(
          `${process.env.VUE_APP_SERVER}/api/check-in-check-outs`
        );
        const responseData = await response.json();
        const data = responseData.data[0].attributes;
        const hours = {
          checkIn: data.CheckIn,
          checkOut: data.CheckOut,
        };
        context.commit("setHours", hours);
      } catch (error) {
        this.error = error;
      }
    },
  },
  modules: {},
};
