<template>
  <the-loading v-if="cardsLoading" :color="'white'" :color2="'white'" />
  <h4
    :class="{ light: settings.theme === 'Light' }"
    class="error__h4"
    v-if="error.error"
  >
    {{ error.errorDescription }}
  </h4>
  <transition-group appear @before-enter="beforeEnter" @enter="enter">
    <div
      v-for="(card, index) in cards"
      :data-index="index"
      :key="card.id"
      class="card"
      :class="{ light: settings.theme === 'Light' }"
      @click="
        card.hiddenDescription || card.hours
          ? $emit(
              'open-modal',
              card.title,
              card.description,
              card.hiddenDescription,
              multiLanguageWeekdaysHandler(card.hours, actualLanguage)
            )
          : null,
          animateClick($event.target)
      "
    >
      <div class="svg">
        <img :src="card.icon" alt="" />
      </div>

      <div :class="{ light: settings.theme === 'Light' }" class="description">
        <div class="hours" v-if="!card.hours.length < 1">
          <div
            :class="{
              closed: checkCurrentDateTime(card.hours).includes(
                translate('Open from', actualLanguage)
              ),
            }"
            class="blink"
          ></div>
          <span>{{ checkCurrentDateTime(card.hours) }}</span>
        </div>
        <h1>{{ card.title }}</h1>
        <h2 v-html="card.description"></h2>
      </div>
      <div v-if="card.hiddenDescription || card.hours" class="arrow">
        <img :src="'/public__assets/tools/arrow.svg'" alt="" />
      </div>
    </div>
  </transition-group>
</template>

<script>
import { parse, isWithinInterval, format, getDay } from "date-fns";
import {
  weekdays,
  openFrom,
  openUntil,
} from "../../tools/languages/dayweeks.js";
import gsap from "gsap";
/* import strapiCalls from '/src/strapi.js'; */
export default {
  data() {
    return {
      language: "English",
      days: {
        Sunday: 7,
        Monday: 1,
        Tuesday: 2,
        Wednesday: 3,
        Thursday: 4,
        Friday: 5,
        Saturday: 6,
      },
    };
  },
  emits: ["open-modal"],
  methods: {
    animateClick(ev) {
      ev.classList.add("clicked");
      setTimeout(function () {
        ev.classList.remove("clicked");
      }, 500);
    },
    checkCurrentDateTime(schedules) {
      const now = new Date();
      return this.handleMultipleSchedules(
        this.sortSchedulesByTime(schedules),
        now
      );
    },
    handleMultipleSchedules(schedules, now) {
      for (let i = 0; i < schedules.length; i++) {
        const { HourFrom, HourTo, DayFrom, DayTo } = schedules[i];
        const fromTime = parse(HourFrom, "HH:mm:ss", new Date());
        const toTime = parse(HourTo, "HH:mm:ss", new Date());

        const timeIsWithinInterval = isWithinInterval(now, {
          start: fromTime,
          end: toTime,
        });
        const dayIsWithinInterval = this.isDayWithinInterval(
          now,
          DayFrom,
          DayTo
        );
        if (dayIsWithinInterval) {
          if (timeIsWithinInterval) {
            return `${this.translate(
              "Open until",
              this.actualLanguage
            )} ${format(toTime, "HH:mm")}`;
          } else if (dayIsWithinInterval && !timeIsWithinInterval) {
            return `${this.translate(
              "Open from",
              this.actualLanguage
            )} ${format(fromTime, "HH:mm")}`;
          } else if (i < schedules.length - 1) {
            const nextFromTime = parse(
              schedules[i + 1].HourFrom,
              "HH:mm:ss",
              new Date()
            );
            return `${this.translate("Open from", this.actualLanguage)} ${
              schedules[i + 1].DayFrom
            } ${format(nextFromTime, "HH:mm")}`;
          } else {
            const nextFromTime = parse(
              schedules[0].HourFrom,
              "HH:mm:ss",
              new Date()
            );
            return `${this.translate("Open from", this.actualLanguage)} ${
              schedules[0].DayFrom
            } ${format(nextFromTime, "HH:mm")}`;
          }
        } else if (i < schedules.length - 1) {
          const nextFromTime = parse(
            schedules[i + 1].HourFrom,
            "HH:mm:ss",
            new Date()
          );
          return `${this.translate("Open from", this.actualLanguage)} ${
            schedules[i + 1].DayFrom
          } ${format(nextFromTime, "HH:mm")}`;
        } else {
          const nextFromTime = parse(
            schedules[0].HourFrom,
            "HH:mm:ss",
            new Date()
          );
          return `${this.translate("Open from", this.actualLanguage)} ${
            schedules[0].DayFrom
          } ${format(nextFromTime, "HH:mm")}`;
        }
      }
    },
    isDayWithinInterval(now, DayFrom, DayTo) {
      let dayOfWeek = getDay(now);
      if (dayOfWeek === 0) {
        dayOfWeek = 7;
      }
      return dayOfWeek >= this.days[DayFrom] && dayOfWeek <= this.days[DayTo];
    },
    sortSchedulesByTime(schedules) {
      let daysOfWeek = [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
      ];
      let today = new Date();
      let currentDay = daysOfWeek[today.getDay()];
      let currentTime = today.getHours() * 60 + today.getMinutes();

      function convertToMinutes(time) {
        let [hours, minutes] = time.split(":").map(Number);
        return hours * 60 + minutes;
      }

      function isActive(schedule) {
        let dayFromIndex = daysOfWeek.indexOf(schedule.DayFrom);
        let dayToIndex = daysOfWeek.indexOf(schedule.DayTo);

        if (dayFromIndex <= dayToIndex) {
          return (
            dayFromIndex <= daysOfWeek.indexOf(currentDay) &&
            daysOfWeek.indexOf(currentDay) <= dayToIndex
          );
        } else {
          return !(
            dayToIndex < daysOfWeek.indexOf(currentDay) &&
            daysOfWeek.indexOf(currentDay) < dayFromIndex
          );
        }
      }

      function isActiveNow(schedule) {
        let fromTime = convertToMinutes(schedule.HourFrom.split(".")[0]);
        let toTime = convertToMinutes(schedule.HourTo.split(".")[0]);
        return (
          isActive(schedule) && fromTime <= currentTime && currentTime <= toTime
        );
      }

      schedules.sort((a, b) => {
        if (isActiveNow(a) != isActiveNow(b))
          return isActiveNow(b) - isActiveNow(a);
        if (isActive(a) != isActive(b)) return isActive(b) - isActive(a);
        if (a.DayFrom != b.DayFrom)
          return daysOfWeek.indexOf(a.DayFrom) - daysOfWeek.indexOf(b.DayFrom);
        return (
          convertToMinutes(a.HourFrom.split(".")[0]) -
          convertToMinutes(b.HourFrom.split(".")[0])
        );
      });

      return schedules;
    },
    multiLanguageWeekdaysHandler(hours, lang) {
      let formattedHours = [];
      hours.forEach((hour) => {
        const fromDay = weekdays[lang][hour.DayFrom];
        const toDay = weekdays[lang][hour.DayTo];
        const fromTime = hour.HourFrom;
        const toTime = hour.HourTo;
        formattedHours.push({
          HourFrom: fromTime,
          HourTo: toTime,
          DayFrom: fromDay,
          DayTo: toDay,
        });
      });
      return formattedHours;
    },
    translate(word, lang) {
      if (word === "Open until") {
        return openUntil[lang];
      } else if (word === "Open from") {
        return openFrom[lang];
      } else {
        console.log("Translation not available");
      }
    },
  },
  computed: {
    cards() {
      return this.$store.getters["cards/cards"];
    },
    cardsLoading() {
      return this.$store.getters["cards/isLoading"];
    },
    error() {
      return this.$store.getters["cards/error"];
    },
    settings() {
      return this.$store.getters["appSettings/settings"];
    },
    actualLanguage() {
      return this.$store.getters["appSettings/actualLanguage"];
    },
  },
  mounted() {
    this.$store.dispatch("appSettings/fetchSettings");
    this.$store.dispatch("cards/fetchCards", this.language);
  },
  //gsap:
  setup() {
    const beforeEnter = (el) => {
      el.style.opacity = 0;
      el.style.transform = "translateX(100px)";
    };
    const enter = (el) => {
      gsap.to(el, {
        opacity: 1,
        x: 0,
        duration: 0.3,
        delay: el.dataset.index * 0.1,
      });
    };
    return { beforeEnter, enter };
  },
};
</script>

<style scoped>
.svg img {
  width: 60px;
  margin: 0.4rem;
}
.card {
  cursor: pointer;
  position: relative;
  width: 48%;
  aspect-ratio: 1/1;
  min-height: 125px;
  background: rgba(255, 255, 255, 0.53);
  border-radius: 25px;
  margin-bottom: 1.2rem;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  transition: 0.3s all;
  overflow: hidden;
}

.card div h2 a {
  pointer-events: unset !important;
}
.card div {
  pointer-events: none;
}
.description {
  width: 100%;
  background: rgba(0, 0, 0, 0.66);
  color: white;
  padding: 0.5rem 0.8rem;
  margin-bottom: 0.8rem;
  position: relative;
}
.description.light {
  background: rgba(255, 255, 255, 0.66) !important;
}
.description.light span,
.description.light h2,
.description.light h1 {
  color: black;
}
h4.Light {
  color: black;
}
.clicked {
  animation: clicked 0.2s ease-in-out;
}
@keyframes clicked {
  0% {
    filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.25));
    transform: scale(1);
  }
  50% {
    filter: drop-shadow(0px 0px 0px rgba(0, 0, 0, 0.25));
    transform: scale(0.95);
  }
  100% {
    filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.25));
    transform: scale(1);
  }
}
.card.active {
  width: 100%;
  min-height: 300px;
  justify-content: flex-start;
}
.rotate {
  transform: rotateX(180deg);
}
h1 {
  font-size: 1rem !important;
  font-weight: 400;
}
h2 {
  font-size: 0.6rem !important;
  font-weight: 300 !important;
}
.arrow {
  cursor: pointer;
  transition: 0.3s transform;
  position: absolute;
  top: 0.4rem;
  right: 0.4rem;
  rotate: -90deg;
}

.hours {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.2rem;
  padding: 0 0.8rem;
  position: absolute;
  top: -1rem;
  left: 0;
}
.hours .blink {
  background-color: #34a853;
  width: 0.5rem;
  aspect-ratio: 1/1;
  border-radius: 0.5rem;
}
.hours .blink.closed {
  background-color: #ce1124;
}
.hours span {
  font-size: 0.6rem;
  text-align: center;
}
@media screen and (max-width: 600px) {
  h1 {
    font-size: 1rem;
  }
  h2 {
    font-size: 0.6rem;
  }
  .card {
    margin-bottom: 0.9rem;
  }
}
</style>
<style>
@keyframes arrowRotate {
  from {
    transform: rotateX(0deg);
  }
  to {
    transform: rotateX(180deg);
  }
}
</style>
