<template>
  <header>
    <the-logo
      v-if="!settings.logo"
      :light="settings.theme === 'Light'"
    ></the-logo>
    <div class="logo">
      <img :src="settings.logo" alt="" />
    </div>
    <div>
      <the-switch />
    </div>
  </header>
</template>

<script>
import TheLogo from "../SVGs/TheLogo.vue";
import TheSwitch from "../SVGs/TheSwitch.vue";
export default {
  props: ["english"],
  components: {
    TheLogo,
    TheSwitch,
  },
  data() {
    return {};
  },
  computed: {
    settings() {
      return this.$store.getters["appSettings/settings"];
    },
  },
  async mounted() {
    await this.$store.dispatch("appSettings/fetchSettings");
  },
};
</script>

<style scoped>
header {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-height: auto;
  padding: 2rem 0rem;
  transition: 0.3s all;
}
h1 {
  font-size: 1rem;
  text-align: center;
  font-weight: 400;
}
</style>
