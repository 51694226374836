<template>
  <li>
    <a
      :class="{ light: settings.theme === 'Light' }"
      target="blank"
      :href="link.link"
      >{{ link.name }}</a
    >
  </li>
</template>

<script>
export default {
  props: ["link"],
  async mounted() {
    await this.$store.dispatch("appSettings/fetchSettings");
  },
  computed: {
    settings() {
      return this.$store.getters["appSettings/settings"];
    },
  },
};
</script>
<style scoped>
li {
  position: relative;
  margin: 0.3rem 0;
}
a.light {
  color: black;
}
a {
  text-decoration: none;
  color: white;
  font-weight: 300;
  font-size: 1.2rem;
}
</style>
