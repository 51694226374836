export default {
  namespaced: true,
  state() {
    return {
      places: [],
      restaurants: [],
      error: {},
    };
  },
  getters: {
    places(state) {
      return state.events;
    },
    restaurants(state) {
      return state.events;
    },
    error(state) {
      return state.error;
    },
  },
  mutations: {
    setPlaces(state, payload) {
      state.events = payload;
    },
    setRestaurants(state, payload) {
      state.events = payload;
    },
    setError(state, payload) {
      state.error = payload;
    },
  },
  actions: {
    async fetchPlaces(context, whichState) {
      try {
        const response = await fetch(
          `http://localhost:3000/api/places?radius=30000&type=${
            whichState === "setPlaces" ? `tourist_attraction` : "restaurant"
          }`
        );
        const responseData = await response.json();
        console.log(responseData);
        const data = responseData.results;
        const places = [];
        data.forEach((place) => {
          places.push({
            id: place.place_id,
            name: place.name,
            icon: place.icon,
            rating: place.rating,
            ratings_total: place.user_ratings_total,
            adres: place.vicinity,
            photo_reference: place.photos[0].photo_reference,
            openNow: place.opening_hours,
          });
        });
        context.commit(`${whichState}`, places);
      } catch (error) {
        this.error = error;
        console.log(error);
      }
    },
  },
  modules: {},
};
