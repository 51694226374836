<template>
  <Suspense>
    <base-container>
      <template v-slot:header>
        <the-header></the-header>
      </template>
      <template v-slot:nav>
        <the-nav></the-nav>
      </template>
      <template v-slot:default>
        <!-- CARDS -->
        <section-container :idObject="'info'">
          <template v-slot:header>
            <the-heading :headerText="translate(actualLanguage, 'Info')">
            </the-heading>
          </template>
          <template v-slot:default>
            <the-card @open-modal="openModal"></the-card>
            <the-timeline></the-timeline>
          </template>
        </section-container>
        <!-- RESTAURANTS -->
        <section-container :display="'block'" :idObject="'restaurants'">
          <template v-slot:header>
            <the-heading
              :headerText="translate(actualLanguage, 'Restaurant and Bars')"
            >
            </the-heading>
          </template>
          <template v-slot:default>
            <the-bigger-card
              type="restaurants"
              @open-modal="openModal"
            ></the-bigger-card>
          </template>
        </section-container>
        <!-- ATTRACTIONS -->
        <section-container :display="'block'" :idObject="'attractions'">
          <template v-slot:header>
            <the-heading :headerText="translate(actualLanguage, 'Attractions')">
            </the-heading>
          </template>
          <template v-slot:default>
            <the-bigger-card
              type="attractions"
              @open-modal="openModal"
            ></the-bigger-card>
          </template>
        </section-container>
        <section-container
          :display="'flex'"
          :justifyContent="'space-around'"
          :idObject="'events'"
        >
          <template v-slot:header>
            <the-heading :headerText="translate(actualLanguage, 'Events')">
            </the-heading>
          </template>
          <template v-slot:default>
            <the-event-card
              v-for="event in events"
              :key="event.id"
              :h1="event.name"
              :h2="event.description"
              :start="event.start"
              :locality="event.localization"
              :link="event.link"
            ></the-event-card>
            <h4 class="error__h4" v-if="events.length < 1">
              {{ outOfEvents.eng }}
            </h4>
          </template>
        </section-container>
        <section-container
          :display="'flex'"
          :gap="'1rem'"
          :justifyContent="'left'"
          :idObject="'media'"
          :paddingLeft="'1rem'"
        >
          <template v-slot:header>
            <the-heading
              :headerText="translate(actualLanguage, 'Social media')"
            >
            </the-heading>
          </template>
          <template v-slot:default>
            <the-media-card
              v-for="media in medias"
              :key="media.id"
              :provider="media.provider"
              :link="media.link"
            >
            </the-media-card>
          </template>
        </section-container>
        <section-container
          :display="'flex'"
          :gap="'1rem'"
          :justifyContent="'left'"
          :alignItems="'flex-start'"
          :idObject="'links'"
          :flexDirection="'column'"
          :paddingLeft="'1rem'"
        >
          <template v-slot:header>
            <the-heading
              :headerText="translate(actualLanguage, 'More Informations')"
            >
            </the-heading>
          </template>
          <template v-slot:default>
            <ul>
              <the-extra-link
                v-for="link in extraLinks"
                :key="link.id"
                :link="link"
              >
              </the-extra-link>
              <li>
                <a target="blank" href="https://gcodem.pl">
                  <img
                    class="hotelHubLogo"
                    src="/public__assets/icons/HotelHubIcon.svg"
                    alt=""
                  />
                </a>
              </li>
            </ul>
          </template>
        </section-container>
      </template>
      <template v-slot:modal>
        <transition name="modal__card">
          <the-modal
            v-if="modalIsVisible"
            @modal-handler="closeModal"
            :title="modalContent.title"
            :h2="modalContent.h2"
            :h3="modalContent.h3"
            :description="modalContent.description"
            :openingHours="modalContent.openingHours"
          ></the-modal>
        </transition>
      </template>
      <template v-slot:footer>
        <the-footer></the-footer>
      </template>
    </base-container>
    <template #fallback>
      <base-container-suspense></base-container-suspense>
    </template>
  </Suspense>
</template>

<script>
import BaseContainer from "./components/layouts/BaseAppContainer.vue";
import BaseContainerSuspense from "./components/layouts/BaseAppContainerSuspense.vue";
import TheHeader from "./components/UI/TheHeader.vue";
import TheNav from "./components/UI/TheNavBar.vue";
import SectionContainer from "./components/layouts/SectionContainer.vue";
import {
  info,
  restaurantAndBars,
  attractions,
  events,
  socialMedia,
  moreInformations,
} from "./tools/languages/dayweeks";

export default {
  components: {
    BaseContainer,
    BaseContainerSuspense,
    SectionContainer,
    TheHeader,
    TheNav,
  },
  data() {
    return {
      modalIsVisible: false,
      modalContent: [],
      icons: [],
      restaurants: [],
      attractions: [],
      phrases: {
        info,
        "restaurant and bars": restaurantAndBars,
        attractions,
        events,
        "social media": socialMedia,
        "more informations": moreInformations,
      },
      outOfEvents: {
        pl: "brak wydarzeń",
        eng: "no events",
      },
      hours: [],
      headers: [],
    };
  },
  methods: {
    translate(lang, phrase) {
      phrase = phrase.toLowerCase(); // This will handle different cases input
      if (this.phrases[phrase] && this.phrases[phrase][lang]) {
        return this.phrases[phrase][lang];
      } else {
        return "Translation not found.";
      }
    },
    openModal(title, h2, description, openingHours) {
      this.modalIsVisible = true;
      this.modalContent = {
        title,
        h2,
        description,
        openingHours,
      };
    },
    closeModal() {
      this.modalIsVisible = false;
    },
  },
  computed: {
    medias() {
      return this.$store.getters["socialMedia/socialmedia"];
    },
    events() {
      return this.$store.getters["events/events"];
    },
    extraLinks() {
      return this.$store.getters["extraLinks/extraLinks"];
    },
    actualLanguage() {
      return this.$store.getters["appSettings/actualLanguage"];
    },
  },
  async mounted() {
    /*  await this.$store.dispatch("googlePlaces/fetchPlaces", "setPlaces");
    await this.$store.dispatch("googlePlaces/fetchPlaces", "setRestaurants"); */
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;1,200;1,300;1,500&display=swap");
html {
  scroll-behavior: smooth;
}
body {
  background: white;
}
#app {
  width: 100%;
  height: auto;
  display: flex;
  align-content: center;
  justify-content: center;
  padding: 1rem 0;
}
.error__h4 {
  font-size: 1rem;
  font-weight: 400;
  width: 100%;
  text-align: center;
  margin-bottom: 1rem;
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  color: white;
}
.hotelHubLogo {
  position: relative;
  max-width: 150px;
  margin: 1rem 0;
  cursor: pointer;
}
ul {
  list-style: none;
}
* {
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
* {
  scrollbar-width: normal;
  scrollbar-color: rgb(0, 0, 0);
}
*::-webkit-scrollbar {
  width: 0vw;
}
*::-webkit-scrollbar-thumb {
  background-color: #fefefe;
  border-radius: 20px;
}
*::-webkit-scrollbar-track {
  background-color: transparent;
  border-left: rgba(180, 22, 95, 0.4) 1px solid;
}
@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}
.carousel__slide > .restaurant__card,
.carousel__slide > .attraction__card {
  transform: scale(0.85) !important;
  opacity: 1;
  transition: 0.5s;
}
.modal__card-enter-active {
  transition: all 0.5s ease-in-out;
}
.modal__card-enter-to,
.modal__card-leave-from {
  transform: translateY(0%);
}
.modal__card-leave-active {
  transition: all 0.5s ease-in-out;
}
.modal__card-leave-to,
.modal__card-enter-from {
  transform: translateY(100%);
}
.carousel__slide--visible > .restaurant__card,
.carousel__slide--visible > .attraction__card {
  opacity: 1;
  transform: rotateY(0);
}
.carousel__slide--next > .restaurant__card,
.carousel__slide--next > .attraction__card {
  transform: scale(0.9) translate(-10px) !important;
  z-index: 1;
}
.carousel__slide--prev > .restaurant__card,
.carousel__slide--prev > .attraction__card {
  transform: scale(0.9) translate(10px) !important;
  z-index: 1;
}
.carousel__slide--active > .restaurant__card,
.carousel__slide--active > .attraction__card {
  transform: scale(1) !important;
  background-color: white !important;
  border: none !important;
  z-index: 2;
}
.carousel__pagination-button {
  background-color: #1b263bd2 !important;
}
.carousel__pagination-button--active {
  background-color: #0d1b2a !important;
}
.carousel__viewport {
  overflow: visible !important;
}
@media screen and (max-width: 610px) {
  #app {
    padding: 0;
  }
}
</style>
