import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import VueDragscroll from "vue-dragscroll";

import TheLogo from "./components/SVGs/TheLogo.vue";
import TheLoading from "./components/SVGs/TheLoading.vue";
import TheHeader from "./components/UI/TheHeader.vue";
import TheFooter from "./components/UI/TheFooter.vue";
import TheHeading from "./components/TheHeading.vue";
import TheModal from "./components/UI/TheModal.vue";
import TheCard from "./components/UI/TheCard.vue";
import TheBiggerCard from "./components/UI/TheBiggerCard.vue";
import TheGooglePlacesCard from "./components/UI/TheGooglePlaceCard.vue";
import TheMediaCard from "./components/UI/TheMedia.vue";
import TheEventCard from "./components/UI/TheEventCard.vue";

import TheExtraLink from "./components/UI/TheExtraLink.vue";
import TheTimeline from "./components/UI/TheTimeLine.vue";

//fontAwesome
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faCookieBite } from "@fortawesome/free-solid-svg-icons";
import { faX } from "@fortawesome/free-solid-svg-icons";
import { faMobileScreenButton } from "@fortawesome/free-solid-svg-icons";
import { faDisplay } from "@fortawesome/free-solid-svg-icons";
import { faFacebook } from "@fortawesome/free-brands-svg-icons";
import { faGithub } from "@fortawesome/free-brands-svg-icons";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
library.add(faEnvelope);
library.add(faMobileScreenButton);
library.add(faDisplay);
library.add(faFacebook);
library.add(faCookieBite);
library.add(faX);
library.add(faGithub);
library.add(faFacebook);
library.add(faLinkedin);
//fontAwesome^

const app = createApp(App);
app.component("font-awesome-icon", FontAwesomeIcon);
app.component("the-header", TheHeader);
app.component("the-footer", TheFooter);
app.component("the-heading", TheHeading);
app.component("the-modal", TheModal);
app.component("the-logo", TheLogo);
app.component("the-loading", TheLoading);
app.component("the-card", TheCard);
app.component("the-media-card", TheMediaCard);
app.component("the-event-card", TheEventCard);
app.component("the-extra-link", TheExtraLink);
app.component("the-bigger-card", TheBiggerCard);
app.component("the-google-places-card", TheGooglePlacesCard);
app.component("the-timeline", TheTimeline);

app.use(VueDragscroll);
app.use(store);
app.use(router);
app.mount("#app");
