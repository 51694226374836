export default {
  namespaced: true,
  state() {
    return {
      extraLinks: [],
      error: {},
    };
  },
  getters: {
    extraLinks(state) {
      return state.events;
    },
    error(state) {
      return state.error;
    },
  },
  mutations: {
    setExtraLinks(state, payload) {
      state.events = payload;
    },
    setError(state, payload) {
      state.error = payload;
    },
  },
  actions: {
    async fetchExtraLinks(context) {
      let lang = context.rootGetters["appSettings/actualLanguage"];
      try {
        const response = await fetch(
          `${process.env.VUE_APP_SERVER}/api/extra-links?locale=${lang}`
        );
        const responseData = await response.json();

        const data = responseData.data;
        const links = [];
        data.forEach((link) => {
          links.push({
            id: link.id,
            link: link.attributes.Link,
            name: link.attributes.Name,
          });
        });
        context.commit("setExtraLinks", links);
      } catch (error) {
        this.error = error;
        console.log("error");
      }
    },
  },
  modules: {},
};
