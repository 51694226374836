<template>
  <header>
    <h1 :style="{ color: color }">{{ headerText }}</h1>
  </header>
</template>

<script>
export default {
  props: ["headerText", "color"],
};
</script>

<style scoped>
header {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
h1 {
  padding: 0 1rem;
  font-size: 1.7rem;
  font-weight: 500;
  white-space: nowrap;
  z-index: 200;
}
.line {
  height: 2px;
  width: 100%;
}
</style>
