import actions from "./actions.js";
export default {
  namespaced: true,
  state() {
    return {
      attractions: [],
      isLoading: false,
      error: {},
    };
  },
  getters: {
    attractions(state) {
      return state.attractions;
    },
    isLoading(state) {
      return state.isLoading;
    },
    error(state) {
      return state.error;
    },
  },
  mutations: {
    setAttractions(state, payload) {
      state.attractions = payload;
    },
    setLoading(state, payload) {
      state.isLoading = payload;
    },
    setError(state, payload) {
      state.error = payload;
    },
  },
  actions: actions,
  modules: {},
};
