<template>
  <the-loading v-if="cardsLoading" :color="'white'" :color2="'white'" />
  <h4 class="error__h4" v-if="error.error">{{ error.errorDescription }}</h4>
  <ul v-dragscroll class="cards">
    <transition-group appear @before-enter="beforeEnter" @enter="enter">
      <li
        v-for="(card, index) in cards"
        :data-index="index"
        :key="card"
        :style="{
          backgroundImage: `${background(card.photo_reference)}`,
        }"
        class="card"
        @click="
          card.hiddenDescription
            ? $emit(
                'open-modal',
                card.title,
                card.description,
                card.hiddenDescription,
                card.hours
              )
            : null,
            animateClick($event.target)
        "
      >
        <div class="description">
          <div class="hours" v-if="!card.openAllDays24Hours">
            <!--             <div
              :class="{
                closed: checkCurrentDateTime(card.hours).includes('from'),
              }"
              class="blink"
            ></div> -->
            <span>{{ card.openNow }}</span>
            <!-- <span>{{ card.photo_reference }}</span> -->
            <!-- <span>{{ fetchPhotos(card.photo_reference) }}</span> -->
          </div>
          <h1>{{ card.name }}</h1>
          <h2>{{ card.adres }}</h2>
        </div>
        <div v-if="card.hiddenDescription" class="arrow">
          <img :src="'/public__assets/tools/arrow.svg'" alt="" />
        </div>
      </li>
    </transition-group>
  </ul>
</template>

<script>
import { dragscroll } from "vue-dragscroll";
import gsap from "gsap";
/* import strapiCalls from '/src/strapi.js'; */
export default {
  directives: {
    dragscroll: dragscroll,
  },
  emits: ["open-modal"],
  props: ["type"],
  data() {
    return {};
  },
  methods: {
    animateClick(ev) {
      ev.classList.add("clicked");
      setTimeout(function () {
        ev.classList.remove("clicked");
      }, 500);
    },
    async fetchPhotos(photo_reference) {
      try {
        const response = await fetch(
          `http://localhost:3000/api/icons?photo_reference=${photo_reference}`
        );
        const responseData = await response.json();
        return responseData;
      } catch (error) {
        this.error = error;
        console.log(error);
      }
    },
    async background(photo_reference) {
      const url = `url("${await this.fetchPhotos(photo_reference)}")`;
      console.log(url);
      return url;
    },
  },
  computed: {
    cards() {
      console.log(this.$store.getters[`googlePlaces/${this.type}`]);
      return this.$store.getters[`googlePlaces/${this.type}`];
    },
    cardsLoading() {
      return this.$store.getters["googlePlaces/isLoading"];
    },
    error() {
      return this.$store.getters["biggerCards/error"];
    },
  },
  mounted() {
    if (this.type === "restaurants") {
      this.$store.dispatch("googlePlaces/fetchPlaces", "setRestaurants");
    } else {
      this.$store.dispatch("googlePlaces/fetchPlaces", "setPlaces");
    }
  },
  //gsap:
  setup() {
    const beforeEnter = (el) => {
      el.style.opacity = 0;
      el.style.transform = "translateX(100px)";
    };
    const enter = (el) => {
      gsap.to(el, {
        opacity: 1,
        x: 0,
        duration: 0.3,
        delay: el.dataset.index * 0.1,
      });
    };
    return { beforeEnter, enter };
  },
};
</script>

<style scoped>
.svg img {
  width: 60px;
  margin: 0.4rem;
}
.cards::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.cards {
  padding: 0;
  display: flex;
  flex-wrap: nowrap;
  list-style: none;
  font-size: 1.3rem;
  font-weight: 500;
  overflow-x: auto;
  cursor: grab;
  gap: 0.5rem;
}
.card {
  cursor: pointer;
  position: relative;
  min-width: 175px;
  max-width: 48%;
  aspect-ratio: 1/1.5;
  min-height: 125px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 25px;
  margin-bottom: 1.2rem;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  flex-direction: column;
  transition: 0.3s all;
}
.card div h2 a {
  pointer-events: unset !important;
}
.card div {
  pointer-events: none;
}
.description {
  width: 100%;
  background: rgba(0, 0, 0, 0.66);
  color: white;
  padding: 0.5rem 0.8rem;
  margin-bottom: 0.8rem;
  position: relative;
}

.clicked {
  animation: clicked 0.2s ease-in-out;
}
@keyframes clicked {
  0% {
    filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.25));
    transform: scale(1);
  }
  50% {
    filter: drop-shadow(0px 0px 0px rgba(0, 0, 0, 0.25));
    transform: scale(0.95);
  }
  100% {
    filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.25));
    transform: scale(1);
  }
}
.card.active {
  width: 100%;
  min-height: 300px;
  justify-content: flex-start;
}
.rotate {
  transform: rotateX(180deg);
}
h1 {
  font-size: 1rem !important;
  font-weight: 400;
}
h2 {
  font-size: 0.6rem !important;
  font-weight: 300 !important;
}
.arrow {
  cursor: pointer;
  transition: 0.3s transform;
  position: absolute;
  top: 0.4rem;
  right: 0.4rem;
  rotate: -90deg;
}

.hours {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.2rem;
  padding: 0 0.8rem;
  position: absolute;
  top: -1rem;
  left: 0;
}
.hours .blink {
  background-color: #34a853;
  width: 0.5rem;
  aspect-ratio: 1/1;
  border-radius: 0.5rem;
}
.hours .blink.closed {
  background-color: #ce1124;
}
.hours span {
  font-size: 0.6rem;
  text-align: center;
}
@media screen and (max-width: 600px) {
  h1 {
    font-size: 1rem;
  }
  h2 {
    font-size: 0.6rem;
  }
  .card {
    margin-bottom: 0.9rem;
  }
}
</style>
<style>
@keyframes arrowRotate {
  from {
    transform: rotateX(0deg);
  }
  to {
    transform: rotateX(180deg);
  }
}
</style>
