import actions from "./actions.js";
export default {
  namespaced: true,
  state() {
    return {
      restaurants: [],
      isLoading: false,
      error: {},
    };
  },
  getters: {
    restaurants(state) {
      return state.restaurants;
    },
    isLoading(state) {
      return state.isLoading;
    },
    error(state) {
      return state.error;
    },
  },
  mutations: {
    setRestaurants(state, payload) {
      state.restaurants = payload;
    },
    setLoading(state, payload) {
      state.isLoading = payload;
    },
    setError(state, payload) {
      state.error = payload;
    },
  },
  actions: actions,
  modules: {},
};
