import { createRouter, createWebHistory } from "vue-router";
import Home from "../App.vue";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      name: "Home",
      component: Home,
    },
    { path: "/info", name: "info", meta: { scrollTo: "#info" } },
    {
      path: "/restaurants",
      name: "restaurants",
      meta: { scrollTo: "#restaurants" },
    },
    {
      path: "/attractions",
      name: "attractions",
      meta: { scrollTo: "#attractions" },
    },
    { path: "/events", name: "events", meta: { scrollTo: "#events" } },
    { path: "/media", name: "media", meta: { scrollTo: "#media" } },
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else if (to.meta.scrollTo) {
      const targetElement = document.querySelector(to.meta.scrollTo);
      if (targetElement) {
        return {
          el: targetElement,
          behavior: "smooth",
        };
      }
    }
    return { x: 0, y: 0 };
  },
});

export default router;
