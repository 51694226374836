import { createStore } from "vuex";
import cardsModule from "./modules/cards/index.js";
import restaurantCardsModule from "./modules/restaurantCards/index.js";
import attractionCardsModule from "./modules/attractionCards/index.js";
import hoursModule from "./modules/hours/index.js";
import eventsModule from "./modules/events/index.js";
import appSettingsModule from "./modules/appSettings/index.js";
import socialMediaModule from "./modules/socialMedia/index.js";
import extraLinksModule from "./modules/extraLinks/index.js";
import googlePlacesModule from "./modules/googlePlaces/index.js";

export default createStore({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    cards: cardsModule,
    restaurantCards: restaurantCardsModule,
    attractionCards: attractionCardsModule,
    hours: hoursModule,
    events: eventsModule,
    appSettings: appSettingsModule,
    socialMedia: socialMediaModule,
    extraLinks: extraLinksModule,
    googlePlaces: googlePlacesModule,
  },
});
