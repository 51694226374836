<template>
  <section
    :class="{ light: settings.theme === 'Light' }"
    :style="{ backgroundImage: `url(${settings.bg})` }"
  >
    <!-- <MessengerChat customer_ID="108634651981788" lang="en_GB" /> -->
    <header>
      <slot name="header"></slot>
    </header>
    <nav>
      <slot name="nav"> </slot>
    </nav>
    <slot></slot>
    <div class="modal__container">
      <slot name="modal"></slot>
    </div>
    <footer>
      <slot name="footer"></slot>
    </footer>
  </section>
</template>

<script>
/* import MessengerChat from "../UI/TheMessengerChat.vue"; */
const test = async () => {
  await new Promise((resolve) => setTimeout(resolve, 1000));

  const test = {
    test1: "tetfgsgsg",
    test2: "tetfgsgsg",
  };
  return test;
};
export default {
  components: {
    /* MessengerChat, */
  },
  async mounted() {
    await this.$store.dispatch("appSettings/fetchSettings");
    await this.$store.dispatch("events/fetchEvents");
    await this.$store.dispatch("extraLinks/fetchExtraLinks");
    await this.$store.dispatch("socialMedia/fetchSocialmedia");

    console.log(`wersja aplikacji: ${process.env.VUE_APP_VERSION}`);
  },
  computed: {
    settings() {
      return this.$store.getters["appSettings/settings"];
    },
  },
  async setup() {
    const test333 = await test();

    return test333;
  },
};
</script>

<style scoped>
header {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1.5rem;
}
nav {
  padding: 1rem 0;
  color: #0d1b2a;
  scroll-behavior: unset !important;
  position: fixed;
  z-index: 1000;
  bottom: 0;
  width: 100%;
  max-width: 500px;
  height: 60px;
  background: #343434;
}
section {
  width: 100%;
  max-width: 500px;
  background: linear-gradient(180deg, #ffffff 0%, #415a77 100%);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  border: 0.5px #415a77 solid;
  color: #0d1b2a;
  position: relative;
}
section::before {
  content: "";
  width: 100%;
  max-width: 500px;
  height: 100%;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 0;
}
section.light::before {
  background-color: rgba(255, 255, 255, 0.7) !important;
}
.modal__container {
  position: fixed;
  bottom: 60px;
  padding-bottom: 0 !important;
  width: 100%;
  max-width: 500px;
  z-index: 999;
}
footer {
  z-index: 2;
  position: relative;
}
@media screen and (max-width: 610px) {
  section {
    border: none;
    border-radius: unset;
  }
  nav {
    padding: 0.7rem 0;
  }
}
</style>

<style></style>
