import actions from './actions.js';
export default {
  namespaced: true,
  state() {
    return {
      cards: [],
      isLoading: false,
      error: {},
    };
  },
  getters: {
    cards(state){
      return state.cards;
    },
    isLoading(state){
      return state.isLoading;
    },
    error(state){
      return state.error;
    },
  },
  mutations: {
    setCards(state, payload){
      state.cards = payload
    },
    setLoading(state, payload){
      state.isLoading = payload
    },
    setError(state, payload){
      state.error = payload
    },
  },
  actions: actions,
  modules: {},
};