export default {
  namespaced: true,
  state() {
    return {
      events: [],
      error: {},
    };
  },
  getters: {
    events(state) {
      return state.events;
    },
    error(state) {
      return state.error;
    },
  },
  mutations: {
    setEvents(state, payload) {
      state.events = payload;
    },
    setError(state, payload) {
      state.error = payload;
    },
  },
  actions: {
    async fetchEvents(context) {
      try {
        const response = await fetch(
          `${process.env.VUE_APP_SERVER}/api/events`
        );
        const responseData = await response.json();

        const data = responseData.data;
        const events = [];
        data.forEach((event) => {
          events.push({
            id: event.id,
            name: event.attributes.Name,
            description: event.attributes.description,
            localization: event.attributes.localization,
            start: event.attributes.start,
            googleLocalization: event.attributes.GoogleLocalization,
            link: event.attributes.linkToEvent,
          });
        });
        context.commit("setEvents", events);
      } catch (error) {
        this.error = error;
        console.log("error");
      }
    },
  },
  modules: {},
};
