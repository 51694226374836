const weekdays = {
  en: {
    Monday: "Monday",
    Tuesday: "Tuesday",
    Wednesday: "Wednesday",
    Thursday: "Thursday",
    Friday: "Friday",
    Saturday: "Saturday",
    Sunday: "Sunday",
  },
  pl: {
    Monday: "Poniedziałek",
    Tuesday: "Wtorek",
    Wednesday: "Środa",
    Thursday: "Czwartek",
    Friday: "Piątek",
    Saturday: "Sobota",
    Sunday: "Niedziela",
  },
  de: {
    Monday: "Montag",
    Tuesday: "Dienstag",
    Wednesday: "Mittwoch",
    Thursday: "Donnerstag",
    Friday: "Freitag",
    Saturday: "Samstag",
    Sunday: "Sonntag",
  },
  fr: {
    Monday: "Lundi",
    Tuesday: "Mardi",
    Wednesday: "Mercredi",
    Thursday: "Jeudi",
    Friday: "Vendredi",
    Saturday: "Samedi",
    Sunday: "Dimanche",
  },
  uk: {
    Monday: "Понеділок",
    Tuesday: "Вівторок",
    Wednesday: "Середа",
    Thursday: "Четвер",
    Friday: "Пʼятниця",
    Saturday: "Субота",
    Sunday: "Неділя",
  },
  ru: {
    Monday: "Понедельник",
    Tuesday: "Вторник",
    Wednesday: "Среда",
    Thursday: "Четверг",
    Friday: "Пятница",
    Saturday: "Суббота",
    Sunday: "Воскресенье",
  },
  es: {
    Monday: "Lunes",
    Tuesday: "Martes",
    Wednesday: "Miércoles",
    Thursday: "Jueves",
    Friday: "Viernes",
    Saturday: "Sábado",
    Sunday: "Domingo",
  },
  it: {
    Monday: "lunedì",
    Tuesday: "martedì",
    Wednesday: "mercoledì",
    Thursday: "giovedì",
    Friday: "venerdì",
    Saturday: "sabato",
    Sunday: "domenica",
  },
  tr: {
    Monday: "Pazartesi",
    Tuesday: "Salı",
    Wednesday: "Çarşamba",
    Thursday: "Perşembe",
    Friday: "Cuma",
    Saturday: "Cumartesi",
    Sunday: "Pazar",
  },
  cs: {
    Monday: "Pondělí",
    Tuesday: "Úterý",
    Wednesday: "Středa",
    Thursday: "Čtvrtek",
    Friday: "Pátek",
    Saturday: "Sobota",
    Sunday: "Neděle",
  },
};

const months = {
  en: {
    January: "January",
    February: "February",
    March: "March",
    April: "April",
    May: "May",
    June: "June",
    July: "July",
    August: "August",
    September: "September",
    October: "October",
    November: "November",
    December: "December",
  },
  pl: {
    January: "Styczeń",
    February: "Luty",
    March: "Marzec",
    April: "Kwiecień",
    May: "Maj",
    June: "Czerwiec",
    July: "Lipiec",
    August: "Sierpień",
    September: "Wrzesień",
    October: "Październik",
    November: "Listopad",
    December: "Grudzień",
  },
  de: {
    January: "Januar",
    February: "Februar",
    March: "März",
    April: "April",
    May: "Mai",
    June: "Juni",
    July: "Juli",
    August: "August",
    September: "September",
    October: "Oktober",
    November: "November",
    December: "Dezember",
  },
  fr: {
    January: "Janvier",
    February: "Février",
    March: "Mars",
    April: "Avril",
    May: "Mai",
    June: "Juin",
    July: "Juillet",
    August: "Août",
    September: "Septembre",
    October: "Octobre",
    November: "Novembre",
    December: "Décembre",
  },
  uk: {
    January: "Січень",
    February: "Лютий",
    March: "Березень",
    April: "Квітень",
    May: "Травень",
    June: "Червень",
    July: "Липень",
    August: "Серпень",
    September: "Вересень",
    October: "Жовтень",
    November: "Листопад",
    December: "Грудень",
  },
  ru: {
    January: "Январь",
    February: "Февраль",
    March: "Март",
    April: "Апрель",
    May: "Май",
    June: "Июнь",
    July: "Июль",
    August: "Август",
    September: "Сентябрь",
    October: "Октябрь",
    November: "Ноябрь",
    December: "Декабрь",
  },
  es: {
    January: "Enero",
    February: "Febrero",
    March: "Marzo",
    April: "Abril",
    May: "Mayo",
    June: "Junio",
    July: "Julio",
    August: "Agosto",
    September: "Septiembre",
    October: "Octubre",
    November: "Noviembre",
    December: "Diciembre",
  },
  it: {
    January: "Gennaio",
    February: "Febbraio",
    March: "Marzo",
    April: "Aprile",
    May: "Maggio",
    June: "Giugno",
    July: "Luglio",
    August: "Agosto",
    September: "Settembre",
    October: "Ottobre",
    November: "Novembre",
    December: "Dicembre",
  },
  tr: {
    January: "Ocak",
    February: "Şubat",
    March: "Mart",
    April: "Nisan",
    May: "Mayıs",
    June: "Haziran",
    July: "Temmuz",
    August: "Ağustos",
    September: "Eylül",
    October: "Ekim",
    November: "Kasım",
    December: "Aralık",
  },
  cs: {
    January: "Leden",
    February: "Únor",
    March: "Březen",
    April: "Duben",
    May: "Květen",
    June: "Červen",
    July: "Červenec",
    August: "Srpen",
    September: "Září",
    October: "Říjen",
    November: "Listopad",
    December: "Prosinec",
  },
};

const today = {
  en: "Today",
  pl: "Dzisiaj",
  de: "Heute",
  fr: "Aujourd'hui",
  uk: "Сьогодні",
  ru: "Сегодня",
  es: "Hoy",
  it: "Oggi",
  tr: "Bugün",
  cs: "Dnes",
};

const tomorrow = {
  en: "Tomorrow",
  pl: "Jutro",
  de: "Morgen",
  fr: "Demain",
  uk: "Завтра",
  ru: "Завтра",
  es: "Mañana",
  it: "Domani",
  tr: "Yarın",
  cs: "Zítra",
};
const openFrom = {
  en: "Open from",
  pl: "Otwarte od",
  de: "Geöffnet von",
  fr: "Ouvert de",
  uk: "Відкрито з",
  ru: "Открыто с",
  es: "Abierto de",
  it: "Aperto da",
  tr: "Açık",
  cs: "Otevřeno od",
};

const openUntil = {
  en: "Open until",
  pl: "Otwarte do",
  de: "Geöffnet bis",
  fr: "Ouvert jusqu'à",
  uk: "Відкрито до",
  ru: "Открыто до",
  es: "Abierto hasta",
  it: "Aperto fino a",
  tr: "Açık olduğu süre",
  cs: "Otevřeno do",
};

const info = {
  en: "Info",
  pl: "Informacje",
  de: "Info",
  fr: "Infos",
  uk: "Інформація",
  ru: "Информация",
  es: "Info",
  it: "Informazioni",
  tr: "Bilgi",
  cs: "Informace",
};

const restaurantAndBars = {
  en: "Restaurants and Bars",
  pl: "Restauracje i bary",
  de: "Restaurants und Bars",
  fr: "Restaurants et bars",
  uk: "Ресторани та бари",
  ru: "Рестораны и бары",
  es: "Restaurantes y bares",
  it: "Ristoranti e bar",
  tr: "Restoranlar ve Barlar",
  cs: "Restaurace a bary",
};

const attractions = {
  en: "Attractions",
  pl: "Atrakcje",
  de: "Sehenswürdigkeiten",
  fr: "Attractions",
  uk: "Атракціони",
  ru: "Достопримечательности",
  es: "Atracciones",
  it: "Attrazioni",
  tr: "Atraksiyonlar",
  cs: "Atrakce",
};

const events = {
  en: "Events",
  pl: "Wydarzenia",
  de: "Veranstaltungen",
  fr: "Événements",
  uk: "Події",
  ru: "События",
  es: "Eventos",
  it: "Eventi",
  tr: "Etkinlikler",
  cs: "Události",
};

const socialMedia = {
  en: "Social Media",
  pl: "Media społecznościowe",
  de: "Soziale Medien",
  fr: "Médias sociaux",
  uk: "Соціальні медіа",
  ru: "Социальные сети",
  es: "Redes sociales",
  it: "Social media",
  tr: "Sosyal Medya",
  cs: "Sociální média",
};

const moreInformations = {
  en: "More informations",
  pl: "Więcej informacji",
  de: "Mehr Informationen",
  fr: "Plus d'informations",
  uk: "Більше інформації",
  ru: "Больше информации",
  es: "Más informaciones",
  it: "Più informazioni",
  tr: "Daha fazla bilgi",
  cs: "Více informací",
};

export {
  weekdays,
  months,
  today,
  tomorrow,
  openFrom,
  openUntil,
  info,
  restaurantAndBars,
  attractions,
  events,
  socialMedia,
  moreInformations,
};
