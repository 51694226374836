<template>
  <div class="card">
    <div class="line"></div>
    <div class="container">
      <div class="checkIn check">
        <h3>Check-in</h3>
        <h2 :class="{ open: hourFromIsAfter }">{{ hourFrom }}</h2>
      </div>
      <div class="checkOut check">
        <h3>Check-Out</h3>
        <h2 :class="{ open: hourToIsAfter }">{{ hourTo }}</h2>
      </div>
    </div>
  </div>
</template>

<script>
import { parse, format, isAfter } from "date-fns";

export default {
  data() {
    return {
      open: false,
      hourFrom: "",
      hourFromIsAfter: false,
      hourTo: "",
      hourToIsAfter: false,
    };
  },
  methods: {
    checkOpen(fromHour, toHour) {
      // Parse string inputs to Date objects
      const fromTime = parse(fromHour, "HH:mm:ss", new Date());
      const toTime = parse(toHour, "HH:mm:ss", new Date());

      // Get the current date and time
      const now = new Date();

      this.hourFromIsAfter = !isAfter(now, fromTime);
      this.hourToIsAfter = !isAfter(now, toTime);
      this.hourFrom = format(fromTime, "HH:mm");
      this.hourTo = format(toTime, "HH:mm");
    },
  },
  computed: {
    hours() {
      return this.$store.getters["hours/hours"];
    },
  },
  async mounted() {
    await this.$store.dispatch("hours/fetchHours");
    this.checkOpen(this.hours.checkIn, this.hours.checkOut);
  },
};
</script>

<style scoped>
.open {
  color: #007c32;
}
.svg img {
  width: 40px;
}
.card {
  width: 100%;
  height: auto;
  background: rgba(255, 255, 255, 0.53);
  border-radius: 25px;
  padding: 0.8rem 1.3rem;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  transition: 0.3s all;
  filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.25));
}
.card.stretch {
  width: 100%;
}
.card .container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.check {
  align-self: center;
}
.check h3 {
  text-align: center;
  font-size: 0.7rem;
  font-weight: 400;
  margin-top: 0.7rem;
}
.line {
  width: 100%;
  height: 3px;
  background: rgba(0, 0, 0, 0.66);
  border-radius: 10px;
  position: relative;
}
.line::before {
  content: "";
  width: 15px;
  height: 7px;
  position: absolute;
  clip-path: polygon(50% 100%, 0 0, 100% 0);
  left: 10%;
  background: rgba(0, 0, 0, 0.66);
}
.line::after {
  content: "";
  width: 15px;
  height: 7px;
  position: absolute;
  clip-path: polygon(50% 100%, 0 0, 100% 0);
  right: 10%;
  background: rgba(0, 0, 0, 0.66);
}
h1 {
  font-size: 1rem;
  font-weight: 500;
}
h2 {
  font-size: 0.8rem;
  font-weight: 500;
}
.checkOut h2 {
  text-align: right;
}
</style>
